<template>
  <div :class="['pdf__wrapper', {'pdf__wrapper-fullscreen': isFullScreen}]">
    <div
      class="pdf"
      v-html="html"
    />
    <button
      type="button"
      class="v-btn v-btn--icon v-btn--round theme--light v-size--default mt-2"
      title="Развернуть на весь экран"
      @click="fullscreen"
    >
      <span class="v-btn__content">
        <i
          aria-hidden="true"
          class="v-icon notranslate material-icons theme--light"
        >
          {{ isFullScreen ? 'close_fullscreen' : 'fullscreen' }}
        </i>
      </span>
    </button>
  </div>
</template>

<script>

export default {
  name: 'FullScreenPdf',

  props: {
    html: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isFullScreen: false,
    }
  },

  methods: {
    fullscreen () {
      this.isFullScreen = !this.isFullScreen

      document.documentElement.style.setProperty('overflow-y', this.isFullScreen ? 'hidden' : 'scroll')
    }
  }
}
</script>

<style lang="scss">
.pdf__wrapper {
  display: flex;
  gap: 0.5rem;
}

.pdf__wrapper-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  padding: 0 1rem 0 2rem;
  background: #eee;

  .pdf > object[type="application/pdf"] {
    height: 100% !important;
  }
}

.pdf {
  flex-grow: 1;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .pdf__wrapper > button {
    display: none;
  }
}
</style>
