<template>
  <VDialog
    v-model="isOpen"
    content-class="preview"
  >
    <div class="preview__actions">
      <VBtn
        class="preview__btn"
        icon
        @click="rotateImage(-90)"
      >
        <VIcon>
          rotate_left
        </VIcon>
      </VBtn>
      <VBtn
        class="preview__btn"
        icon
        @click="rotateImage(90)"
      >
        <VIcon>
          rotate_right
        </VIcon>
      </VBtn>
      <VBtn
        class="preview__btn"
        icon
        @click="() => isOpen = false"
      >
        <VIcon>
          close
        </VIcon>
      </VBtn>
    </div>

    <img
      class="preview__img"
      :src="src"
      :style="`transform: rotate(${currentRotation}deg);`"
      alt=""
    >
  </VDialog>
</template>

<script>

export default {
  name: 'TrainingPreview',
  data () {
    return {
      currentRotation: 0,
      isOpen: false,
      src: ''
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    preview (evt) {
      const target = evt.target
      if (target && target.nodeType === Node.ELEMENT_NODE && target.hasAttribute('preview-src')) {
        switch (target.nodeName) {
          case 'A':
          case 'IMG':
            evt.stopPropagation()
            evt.preventDefault()

            this.src = target.getAttribute('preview-src')
            this.isOpen = true
            break
        }
      }
    },

    rotateImage (value) {
      this.currentRotation += value
    },

    register () {
      document.addEventListener('click', this.preview)
    },

    unregister () {
      document.removeEventListener('click', this.preview)
      this.isOpen = false
      this.src = ''
    }
  }
}
</script>

<style lang="scss">
.preview {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  box-shadow: none;

  &__img {
    display: block;
    max-height: 90vh;
    max-width: 90vw;
    height: auto;
    width: auto;
    transition-duration: 150ms;
  }

  &__actions {
    width: auto;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__btn {
    background-color: #fff;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}

.preview__img {
  max-height: 90vw;
  max-width: 90vh;
}

.v-dialog.preview {
  max-height: 100%;
}

[preview-src] {
  cursor: zoom-in;
}
</style>
